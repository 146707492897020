import React from "react"
import { Item } from "semantic-ui-react"
import SEO from "../../../components/seo"
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Publicly traded companies";

const data = require("../../../data/public-companies.json");

const PublicCompanies = ({ mobile }) => {
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={{ ...vizBackgroundStyle(mobile), width: mobile ? "100%" : "50%" }}>
                    <div>
                        <Item.Group divided relaxed>
                            {
                                data.companies.map(details => (
                                    <Item key={details.symbol}>
                                        <Item.Image size="small" src={`/public-companies/${details.symbol}.png`} />
                                        <Item.Content>
                                            <Item.Header>{details.name} ({details.symbol})</Item.Header>
                                            <Item.Meta>
                                                {
                                                        <>
                                                            <span>See on</span>
                                                            <span>{<a href={details.link}>Google Finance</a>}
                                                            </span>
                                                        </>
                                                }
                                            </Item.Meta>
                                            <Item.Description>
                                                {details.synopsis}
                                                <div style={{ marginBottom: 5 }}>
                                                    {details.description}
                                                </div>
                                                {
                                                    mobile ?
                                                        null :
                                                        <div style={{height: 400}}>
                                                            <TradingViewWidget
                                                                autosize
                                                                height="400"
                                                                symbol={details.symbol}
                                                                theme={Themes.DARK}
                                                            />
                                                        </div>
                                                }
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                ))
                            }
                        </Item.Group>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublicCompanies;
